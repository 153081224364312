import { Grid, Typography } from '@material-ui/core';
import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { useStyles } from './privacy';

const TermsOfService: React.FC = () => {
  const classes = useStyles();

  return (
    <Layout isHeaderSticky={false}>
      <SEO title="Terms of Use" />
      <Grid container spacing={3} className={classes.container}>
        <Grid item md={2} sm={1} />
        <Grid item xs={10} md={8}>
          <Typography variant="h3">Terms of Use</Typography>
          <Typography variant="h5" className={classes.subheader}>
            1. ACCEPTANCE OF TERMS OF USE
          </Typography>
          <Typography variant="body1" className={classes.body}>
            The following terms and conditions (collectively, these &ldquo;Terms
            of Use&rdquo;) apply to your use of the services provided by our
            website and mobile applications (the &ldquo;Services&rdquo;). These
            Terms of Use supersede and replace any and all prior oral or written
            understandings or agreements regarding the Services. By using this
            site, you agree to the Terms of Use listed below.
          </Typography>

          <Typography variant="h5" className={classes.subheader}>
            2. DESCRIPTION OF SERVICES
          </Typography>
          <Typography variant="body1" className={classes.body}>
            We provide users with access to educational training materials (the
            &ldquo;Services&rdquo;). At any time and at our sole discretion, we
            may change, suspend, or discontinue any aspect of the Services. The
          </Typography>

          <Typography variant="h5" className={classes.subheader}>
            3. ACCESSING THE SERVICES
          </Typography>
          <Typography variant="body1" className={classes.body}>
            Finding Focus is accessed via https://findingfocus.app or the
            Finding Focus iOS and Android apps. We cannot guarantee that the
            Services will be available at all times. We also reserve the right
            to disable any user account at our sole discretion.
          </Typography>

          <Typography variant="h5" className={classes.subheader}>
            4. INTELLECTUAL PROPERTY RIGHT
          </Typography>
          <Typography variant="body1" className={classes.body}>
            This website and its original content are protected by United States
            and international copyright laws. By using our Services, you agree
            to not copy, modify, create derivative works of, publicly display,
            or republish any of our copyrighted material.
            <br />
            <br />
            Pursuant to the Digital Millennium Copyright Act, all websites must
            provide a point of contact for claims of potential copyright
            infringement. If you have a good faith belief that any material
            available through the Services infringes upon your copyrights,
            please contact:
            <br />
            <br />
            Michael Mrazek
            <br />
            Research Associate Professor
            <br />
            Department of Psychology <br />
            University of Texas at Austin <br />
            108 E Dean Keeton St. <br />
            Austin, TX 78712 <br />
            Telephone: (507) 398-6776 <br />
            Email: michael.mrazek@utexas.edu
          </Typography>

          <Typography variant="h5" className={classes.subheader}>
            5. PRIVACY
          </Typography>
          <Typography variant="body1" className={classes.body}>
            By using the Services, you consent to the terms of the Privacy
            Policy, which is located at https://www.findingfocus.app/privacy
          </Typography>

          <Typography variant="h5" className={classes.subheader}>
            6. GEOGRAPHIC RESTRICTIONS
          </Typography>
          <Typography variant="body1" className={classes.body}>
            We provide this Website for use only by persons located in the
            United States. We make no claims that the Website or any of its
            content is accessible, appropriate, or legal outside of the United
            States. If you access the Website from outside the United States,
            you do so on your own initiative and are responsible for compliance
            with local laws.
          </Typography>

          <Typography variant="h5" className={classes.subheader}>
            7. CHANGES TO THE TERMS OF USE
          </Typography>
          <Typography variant="body1" className={classes.body}>
            We may change these Terms of Use periodically. If we do, we will
            email you with notice of the changes. At the bottom of the Terms of
            Use, we will also indicate the date these terms were last revised.
            You are bound by any changes to the Terms of Use when you use the
            Services after such changes have been first posted.
          </Typography>

          <Typography variant="h5" className={classes.subheader}>
            8. DISCLAIMER
          </Typography>
          <Typography variant="body1" className={classes.body}>
            Your use of this website, including any risk it entails, is at your
            sole discretion. The services are provided &ldquo;as is&rdquo; and
            &ldquo;as available&rdquo; without warranties of any kind. To the
            fullest extent permissible under applicable law, we disclaim all
            warranties, including, but not limited to: implied warranties of
            merchantability, fitness for a particular purpose, that functions
            will remain uninterrupted or error-free; that materials are correct
            or reliable; that defects will be corrected; or that this site or
            the server that makes it available are free of viruses or other
            harmful components.
          </Typography>

          <Typography variant="h5" className={classes.subheader}>
            9. LIMITATION OF LIABILITY
          </Typography>
          <Typography variant="body1" className={classes.body}>
            To the fullest extent permissible under applicable law, we shall not
            be liable for any special or consequential damages that result from
            the use of or the inability to use the services provided on this
            site. In no event shall our total liability to you for all damages,
            losses, and causes of action exceed the amount paid by you, if any,
            for accessing these services.
          </Typography>
          <Typography variant="body1" className={classes.body}>
            <br />
            If you have questions about this privacy policy, please contact
            support@findingfocus.app.
            <br />
            <br />
            Last revised on 12/1/2024
          </Typography>
        </Grid>
        <Grid item md={2} sm={1} />
      </Grid>
    </Layout>
  );
};

export default TermsOfService;
